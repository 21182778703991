<template>
  <div class="loaderContainer">
    <div class="loader" v-if="loader" :style="{'width': loader + '%'}" />
    <div class="notLoader" v-else/>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      timeout: false,
      timeout2: false,
      timer: 30000,
      loader: 0
    }
  },
  methods: {
    getTimer () {
      this.loader = this.loader + ((100 / this.timer) * 100)
      if (this.loader > 100) {
        this.loader = 0
      }
      this.timeout2 = setTimeout(function () {
        this.getTimer()
      }.bind(this), 100)
    },
    getUpdateData () {
      if (typeof this.$getUserVariables().user === 'undefined') {
        return false
      }
      this.$flix_post({
        url: 'booking/updateData',
        data: {
          user: this.$getUserVariables().user.md5_id,
          timeout: false,
          time: this.$store.getters.bookingUpdateKey
        },
        callback: function (ret) {
          this.$store.commit('setBookingUpdateKey', ret.data[2])
          this.timeout = setTimeout(function () {
            this.getUpdateData()
          }.bind(this), this.timer)
          clearTimeout(this.timeout2)
          this.getTimer()
          if (!ret.data[0]) {
            return false
          }
          ret.data[1].forEach(function (d) {
            this.$store.commit('addBooking', { form: d.form, data: d })
          }.bind(this))
        }.bind(this)
      })
    }
  },
  mounted () {
    this.getUpdateData()
    this.getTimer()
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
    clearTimeout(this.timeout2)
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .loaderContainer
    background-color: #222
  .loader
    background-color: lighten(#222, 50)
    transition: all 0.1s ease-in-out
    height: 4px
    border-right: 4px solid white
  .notLoader
    background-color: lighten(#222, 50)
    height: 4px
</style>
